import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../features/accordion/card";
import connect from "react-redux/es/connect/connect";
import { Drawer } from "antd";
import { BLOG_URL } from "../../../config";
function MobileMainNav(props) {
	const [childrenDrawer, setChildrenDrawer] = useState(false);
	const [selectedCat, setSelectedCat] = useState();
	const { categories, setVisible, userData } = props;
	const isLoggedIn = userData.isLoggedIn;
	let role = userData.isLoggedIn && userData.data.role;
	const brokerListingsPrams = { colorType: "", wattage: "" };
	const showChildrenDrawer = (cat) => {
		setSelectedCat(cat);
		setChildrenDrawer(true);
	};
	const onChildrenDrawerClose = () => {
		setChildrenDrawer(false);
	};

	useEffect(() => {
		let indexedCategories = {};
		categories.forEach((category) => {
			indexedCategories = {
				...indexedCategories,
				...{ [category.id]: { ...category } },
			};
		});
	}, []);

	const cloasedrwerHandler = () => {
		setVisible(false);
		setChildrenDrawer(false);
	};
	let catName = (
		<div className="drawer-header">
			<button
				className="backToNav"
				onClick={() => setChildrenDrawer(false)}>
				<i className="fal fa-arrow-left"></i>
			</button>
			<h6>{selectedCat && selectedCat.name}</h6>
		</div>
	);
	return (
		<nav className="mobile-nav">
			<h3 className="mobile-header"> USER </h3>
			{/* Login URL's */}
			<ul className="navigation-sub-category">
				<li>
					<Link
						to={`${process.env.PUBLIC_URL}/`}
						onClick={() => setVisible(false)}>
						Home
					</Link>
				</li>
				{userData.isLoggedIn !== true && (
					<Fragment>
						<li>
							<Link
								to={`${process.env.PUBLIC_URL}/register`}
								onClick={() => setVisible(false)}>
								Join Free
							</Link>
						</li>
						<li>
							<Link
								to={`${process.env.PUBLIC_URL}/login`}
								onClick={() => setVisible(false)}>
								Sign In
							</Link>
						</li>
					</Fragment>
				)}
			</ul>
			{isLoggedIn && (
				<>
					<h3 className={"mobile-header"}> Bid/Offer </h3>{" "}
					<ul className={"navigation-sub-category"}>
						{role.length > 1 && role.includes("customer", "seller") && (
							<>
								<li>
									<Link
										to={`${process.env.PUBLIC_URL}/trader/chat/seller`}
										onClick={() => setVisible(false)}>
										Selling Offers
									</Link>
								</li>
								<li>
									<Link
										to={`${process.env.PUBLIC_URL}/trader/chat/buyer`}
										onClick={() => setVisible(false)}>
										Buying Bids
									</Link>
								</li>
							</>
						)}
						{role.length === 1 && role.includes("customer") && (
							<li>
								<Link
									to={`${process.env.PUBLIC_URL}/trader/chat/buyer`}
									onClick={() => setVisible(false)}>
									Buying Bids
								</Link>
							</li>
						)}
						{role.length === 1 && role.includes("seller") && (
							<li>
								<Link
									to={`${process.env.PUBLIC_URL}/trader/chat/seller`}
									onClick={() => setVisible(false)}>
									Selling Offers
								</Link>
							</li>
						)}
					</ul>
				</>
			)}
			<h3 className="mobile-header"> Sell On Sunhub </h3>
			<ul className="navigation-sub-category">
				<li onClick={cloasedrwerHandler}>
					<Link to={`${process.env.PUBLIC_URL}/solar-trader-platform`}>Solar Trader Platform</Link>
				</li>
			</ul>
			<Drawer
				title={catName}
				width={320}
				closable={false}
				onClose={onChildrenDrawerClose}
				visible={childrenDrawer}
				placement={"left"}>
				<ul className="navigation-sub-category">
					{selectedCat &&
						selectedCat.sub_categories.map((sub, i) => {
							return (
								<li
									key={i}
									onClick={cloasedrwerHandler}>
									<Link
										to={`${process.env.PUBLIC_URL}/shop/product/${selectedCat.name.replace(/\s+/g, "-").toLowerCase()}/${sub.name.replace(/\s+/g, "-").toLowerCase()}` || "#"}
										className="">
										{sub.name}
									</Link>
								</li>
							);
						})}
				</ul>
			</Drawer>
			<div className="mobile-menu">
				<h3 className={"mobile-header"}>Shop By Categories</h3>
				{categories && [
					...categories.map((cat, index) => {
						return (
							<Card
								adClass="card-box"
								key={index}
								cat={cat}
								title={cat.name.replace("-", " ").toUpperCase()}
								showChildrenDrawer={showChildrenDrawer}
							/>
						);
					}),
				]}
				<h3 className={"mobile-header"}> Industries </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to="/industries/residential"
							onClick={() => setVisible(false)}>
							Residential Installers
						</Link>
					</li>
					<li>
						<Link
							to="/industries/commercial"
							onClick={() => setVisible(false)}>
							Commercial Installers
						</Link>
					</li>
					<li>
						<Link
							to="/industries/utility"
							onClick={() => setVisible(false)}>
							Utility
						</Link>
					</li>
					<li>
						<Link
							to="/industries/epc"
							onClick={() => setVisible(false)}>
							EPC’s
						</Link>
					</li>
					<li>
						<Link
							to="/industries/manufacturers"
							onClick={() => setVisible(false)}>
							Manufacturers
						</Link>
					</li>
					<li>
						<Link
							to="/industries/distributors"
							onClick={() => setVisible(false)}>
							Distributors
						</Link>
					</li>
				</ul>
				<h3 className={"mobile-header"}> Solutions </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to="/solutions/equipment-procurement"
							onClick={() => setVisible(false)}>
							Equipment Procurement
						</Link>
					</li>
					<li>
						<Link
							to="/solutions/logistics-warehousing"
							onClick={() => setVisible(false)}>
							Logistics & Warehousing
						</Link>
					</li>
					<li>
						<Link
							to="/solutions/project-planning"
							onClick={() => setVisible(false)}>
							Project Planning
						</Link>
					</li>
					<li>
						<Link
							to="/solutions/consulting"
							onClick={() => setVisible(false)}>
							Consulting
						</Link>
					</li>
					<li>
						<Link
							to="/solutions/contract-negotiation"
							onClick={() => setVisible(false)}>
							Contract Negotiation & Generation
						</Link>
					</li>
					<li>
						<Link
							to="/solutions/e-commerce-distribution"
							onClick={() => setVisible(false)}>
							E-Commerce Distribution
						</Link>
					</li>
				</ul>
				<h3 className={"mobile-header"}> Shop By Watts </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to={`/trader/find${brokerListingsPrams.wattage}${brokerListingsPrams.colorType}`}
							onClick={() => setVisible(false)}>
							Solar Trader Deal Listings
						</Link>
					</li>
				</ul>
				<h3 className={"mobile-header"}>Brands</h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/brands`}
							onClick={() => setVisible(false)}>
							Shop By Brands
						</Link>
					</li>
				</ul>
				<h3 className={"mobile-header"}> Resources </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/order-status`}
							onClick={() => setVisible(false)}>
							Order Status
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/events`}
							className="sf-with-li">
							Sunhub Events
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/rv-quotation`}
							onClick={() => setVisible(false)}>
							RV Quotation
						</Link>
					</li>
					<li>
						<a href={BLOG_URL}>Blog</a>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/storefront-listing`}
							onClick={() => setVisible(false)}>
							Store Front Listing
						</Link>
					</li>
					<li>
						<a
							href="https://forum.sunhub.com/"
							target="blank">
							Forum
						</a>
					</li>
				</ul>
				<h3 className={"mobile-header"}> Custom Order </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/custom-order-fulfillment`}
							onClick={() => setVisible(false)}>
							Request Inventory
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/custom-order-listing`}
							onClick={() => setVisible(false)}>
							Inventory Listing
						</Link>
					</li>
				</ul>
				<h3 className={"mobile-header"}> SUNHUB </h3>
				<ul className={"navigation-sub-category"}>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/about-sunhub`}
							onClick={() => setVisible(false)}>
							About Sunhub
						</Link>
					</li>{" "}
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/contact-us`}
							onClick={() => setVisible(false)}>
							Contact Us
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/faq`}
							onClick={() => setVisible(false)}>
							FAQ's
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/terms-of-use`}
							onClick={() => setVisible(false)}>
							Terms of Use
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/terms-of-sale`}
							onClick={() => setVisible(false)}>
							Terms of Sale
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/privacy-notice`}
							onClick={() => setVisible(false)}>
							Privacy Policy
						</Link>
					</li>
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/buyer-terms`}
							onClick={() => setVisible(false)}>
							Solar Trader Buyer Terms
						</Link>
					</li>{" "}
					<li>
						<Link
							to={`${process.env.PUBLIC_URL}/seller-terms`}
							onClick={() => setVisible(false)}>
							Solar Trader Seller Terms
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
}
// export default MobileMainNav;
export const mapStateToProps = (state) => {
	return {
		categories: state.categories.categories ? state.categories.categories : [],
		brands: state.brands ? (state.brands.brands ? state.brands.brands : []) : [],

		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
	};
};

export default connect(mapStateToProps, null)(MobileMainNav);
