import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Slider, Button, Radio, InputNumber } from "antd";
import { brokerListingButtons } from "../../../../../src/pages/broker/create-deal-constant";
import { DollarOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { modifyBrokerUrl } from "../../../../utils";
const AdvanceSearchFilter = (props) => {
	const { searchButtonInsideHeader } = props;
	const [form] = Form.useForm();
	let history = useHistory();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [minError, setMinError] = useState("");
	const [maxError, setMaxError] = useState("");
	const [sliderRange, setSliderRange] = useState([0, 1]);
	const [sliderStep, setSliderStep] = useState(0.1);
	const [brokerListingsPrams, setBrokerListingsPrams] = useState({
		wattage: "",
		moqUnit: "",
		category: brokerListingButtons.category[0].param,
		priceRange: [1, 100000],
		minPrice: 0,
		maxPrice: 1,
	});
	useEffect(() => {
		const isPanels = brokerListingsPrams.category.includes("panels");
		let shouldUpdate = false;
		let newMinPrice = brokerListingsPrams.minPrice;
		let newMaxPrice = brokerListingsPrams.maxPrice;

		if (isPanels) {
			if (brokerListingsPrams.minPrice > 1 || brokerListingsPrams.minPrice < 0) {
				setMinError("For panels, the value cannot be greater than 1 or less than 0.");
				newMinPrice = Math.max(0, Math.min(1, brokerListingsPrams.minPrice));
				shouldUpdate = true;
			} else {
				setMinError("");
			}

			if (brokerListingsPrams.maxPrice > 1 || brokerListingsPrams.maxPrice < 0) {
				setMaxError("For panels, the value cannot be greater than 1 or less than 0.");
				newMaxPrice = Math.max(0, Math.min(1, brokerListingsPrams.maxPrice));
				shouldUpdate = true;
			} else {
				setMaxError("");
			}
		}
		setSliderRange(isPanels ? [0, 1] : [1, 100000]);
		setSliderStep(isPanels ? 0.001 : 1);

		const defaultMinPrice = isPanels ? 0 : 1;
		const defaultMaxPrice = isPanels ? 1 : 100000;

		if (newMinPrice !== brokerListingsPrams.minPrice || newMaxPrice !== brokerListingsPrams.maxPrice || shouldUpdate) {
			setBrokerListingsPrams({
				...brokerListingsPrams,
				minPrice: newMinPrice,
				maxPrice: newMaxPrice,
				priceRange: [defaultMinPrice, defaultMaxPrice],
			});
		}
	}, [brokerListingsPrams.category]);

	const handleOk = () => {
		const isPanel = brokerListingsPrams.category.includes("panels");
		setBrokerListingsPrams({
			wattage: "",
			moqUnit: "",
			category: brokerListingButtons.category[0].param,
			priceRange: [1, 100000],
			minPrice: 0,
			maxPrice: 1,
		});
		form.resetFields();
		setSliderRange(isPanel ? [0, 1] : [1, 100000]);
		setSliderStep(isPanel ? 0.001 : 1);
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		const isPanel = brokerListingsPrams.category.includes("panels");
		setBrokerListingsPrams({
			wattage: "",
			moqUnit: "",
			category: brokerListingButtons.category[0].param,
			priceRange: [1, 100000],
			minPrice: 0,
			maxPrice: 1,
		});

		setSliderRange(isPanel ? [0, 1] : [1, 100000]);
		setSliderStep(isPanel ? 0.001 : 1);
		form.resetFields();
		setIsModalOpen(false);
	};
	const onSliderChange = (value) => {
		setMaxError("");
		setMinError("");
		setBrokerListingsPrams({
			...brokerListingsPrams,
			minPrice: value[0],
			maxPrice: value[1],
			priceRange: value,
		});
		form.setFieldsValue({ min_price: value[0], max_price: value[1] });
	};

	const minPriceHandler = (value) => {
		const maxPrice = brokerListingsPrams.maxPrice;
		if (
			brokerListingsPrams.category.includes("inverters") ||
			brokerListingsPrams.category.includes("batteries") ||
			brokerListingsPrams.category.includes("panels")
		) {
			if (value > maxPrice) {
				setMinError("Min price cannot be greater than max price.");
			} else {
				setMinError("");
			}
		} else if (brokerListingsPrams.category.includes("panels") && (value > 1 || value < 0)) {
			setMinError("For panels, the value should be 0 to 1.");
		} else {
			setMinError("");
		}

		if (typeof value === "number" && !isNaN(value)) {
			setBrokerListingsPrams({
				...brokerListingsPrams,
				minPrice: value,
				priceRange: [value, maxPrice],
			});
			form.setFieldsValue({ priceRange: [value, maxPrice] });
		}
	};

	const maxPriceHandler = (value) => {
		const minPrice = brokerListingsPrams.minPrice;

		if (
			brokerListingsPrams.category.includes("inverters") ||
			brokerListingsPrams.category.includes("batteries") ||
			brokerListingsPrams.category.includes("panels")
		) {
			if (value < minPrice) {
				setMaxError("Max price cannot be less than min price.");
			} else {
				setMaxError("");
			}
		} else if (brokerListingsPrams.category.includes("panels") && (value > 1 || value < 0)) {
			setMaxError("For panels, the value should be 0 to 1.");
		} else {
			setMaxError("");
		}

		if (typeof value === "number" && !isNaN(value)) {
			setBrokerListingsPrams({
				...brokerListingsPrams,
				maxPrice: value,
				priceRange: [minPrice, value],
			});
			form.setFieldsValue({ priceRange: [minPrice, value] });
		}
	};

	const handleCategory = (item) => {
		form.resetFields();
		const isPanels = item.param.includes("panels");
		const defaultMinPrice = isPanels ? 0 : 1;
		const defaultMaxPrice = isPanels ? 1 : 100000;
		setBrokerListingsPrams({
			...brokerListingsPrams,
			category: modifyBrokerUrl(brokerListingsPrams.category, item.param),
			minPrice: "",
			maxPrice: "",
			priceRange: [defaultMinPrice, defaultMaxPrice],
		});
		setSliderRange(isPanels ? [0, 1] : [1, 100000]);
		setSliderStep(isPanels ? 0.001 : 1);

		setBrokerListingsPrams({
			...brokerListingsPrams,
			category: modifyBrokerUrl(brokerListingsPrams.category, item.param),
			minPrice: defaultMinPrice,
			maxPrice: defaultMaxPrice,
			priceRange: [defaultMinPrice, defaultMaxPrice],
		});
		// Reset the form values
		form.resetFields(["min_price", "max_price"]);

		setMaxError("");
		setMinError("");
	};
	const handleViewAllClick = () => {
		const maxRange = "/pr_" + brokerListingsPrams.minPrice;
		const minRange = "/pr_" + brokerListingsPrams.maxPrice;
		const isWattage = !(
			brokerListingsPrams.category.includes("batteries") || brokerListingsPrams.category.includes("inverters")
		)
			? brokerListingsPrams.wattage
			: "";

		const isMoqUnit = !(
			brokerListingsPrams.category.includes("batteries") || brokerListingsPrams.category.includes("inverters")
		)
			? brokerListingsPrams.moqUnit
			: "";

		if (isWattage || isMoqUnit || brokerListingsPrams) {
			history.push(
				`${process.env.PUBLIC_URL}/trader/find${isWattage}${isMoqUnit}${brokerListingsPrams.category}${maxRange}${minRange}`
			);
			form.resetFields();
			setBrokerListingsPrams({
				wattage: "",
				moqUnit: "",
				category: brokerListingButtons.category[0].param,
				priceRange: [1, 100000],
				minPrice: 0,
				maxPrice: 1,
			});
		}
		form.setFieldsValue({ viewAllClicked: true });
		form.submit();

		setIsModalOpen(false);
	};

	return (
		<>
			<Modal
				title="Configure Your Deal"
				visible={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				className="advance-search-modal"
				width={576}
				footer={[
					<Button
						disabled={minError || maxError}
						onClick={handleViewAllClick}
						aria-label="search deals Link"
						className="btn btn-primary btn-round h-auto">
						View All Items
					</Button>,
				]}
				centered>
				<Form
					validateTrigger="onChange"
					id="create_deal_form"
					layout="vertical"
					form={form}
					scrollToFirstError>
					<div className="row">
						<div className="col-12 block-border">
							<h6>Type of Equipment</h6>
							<Form.Item
								name="equipmentType"
								className="mb-0 mt-2">
								<Radio.Group
									className="custom-radio-filter-buttons"
									defaultValue={brokerListingButtons.category[0].param}>
									{brokerListingButtons.category.map((item, index) => (
										<Radio.Button
											className="h-auto"
											onChange={() => handleCategory(item)}
											value={item.param}
											key={item.imgUrl}>
											<img
												alt="Equipment-img"
												className="d-inline mr-2"
												src={`${process.env.PUBLIC_URL}/${item.imgUrl}`}
											/>
											{item.label}
										</Radio.Button>
									))}
								</Radio.Group>
							</Form.Item>
						</div>

						<div className="col-12 block-border">
							<h6>Price</h6>
							<Form.Item
								name="priceRange"
								className="mb-0">
								<Slider
									range
									defaultValue={[0, 100000]}
									value={brokerListingsPrams.priceRange}
									step={sliderStep}
									min={sliderRange[0]}
									max={sliderRange[1]}
									onChange={onSliderChange}
								/>
							</Form.Item>
							<div className="row">
								<div className="col-lg-6 col-sm-12 mt-3">
									<Form.Item
										name="min_price"
										className="mb-0">
										{!(
											brokerListingsPrams.category.includes("batteries") ||
											brokerListingsPrams.category.includes("inverters")
										) && <label>Min Price per Watt</label>}
										{(brokerListingsPrams.category.includes("batteries") ||
											brokerListingsPrams.category.includes("inverters")) && <label>Min Price per Unit</label>}

										<Input.Group compact>
											<InputNumber
												value={brokerListingsPrams.minPrice}
												onChange={minPriceHandler}
												type="number"
												placeholder="Min Value"
												size="large"
											/>
											<Button
												className="h-auto w-25"
												icon={<DollarOutlined />}
											/>
										</Input.Group>
									</Form.Item>
									<div style={{ color: "#ff4d4f" }}>{minError}</div>
								</div>
								<div className="col-lg-6 col-sm-12 mt-3">
									<Form.Item
										name="max_price"
										className="mb-0">
										{!(
											brokerListingsPrams.category.includes("batteries") ||
											brokerListingsPrams.category.includes("inverters")
										) && <label>Max Price per Watt</label>}
										{(brokerListingsPrams.category.includes("batteries") ||
											brokerListingsPrams.category.includes("inverters")) && <label>Max Price per Unit</label>}
										<Input.Group compact>
											<InputNumber
												value={brokerListingsPrams.maxPrice}
												onChange={maxPriceHandler}
												type="number"
												placeholder="Max Value"
												size="large"
											/>
											<Button
												className="h-auto w-25"
												icon={<DollarOutlined />}
											/>
										</Input.Group>
									</Form.Item>
									<div style={{ color: "#ff4d4f" }}>{maxError}</div>
								</div>
							</div>
						</div>
						{!(
							brokerListingsPrams.category.includes("batteries") || brokerListingsPrams.category.includes("inverters")
						) && (
							<div className="col-12 block-border">
								<h6>Panel Wattage</h6>

								{brokerListingButtons.wattage.map((item, index) => (
									<Button
										shape="round"
										key={item.label}
										className={`select-filter-btn h-auto rounded-pills ${
											brokerListingsPrams.wattage.includes(item.param) && "selected"
										}`}
										onClick={() =>
											setBrokerListingsPrams({
												...brokerListingsPrams,
												wattage: modifyBrokerUrl(brokerListingsPrams.wattage, item.param),
											})
										}>
										{item.label}
									</Button>
								))}
							</div>
						)}
						{!(
							brokerListingsPrams.category.includes("batteries") || brokerListingsPrams.category.includes("inverters")
						) && (
							<div className="col-12">
								<h6>Quantity</h6>
								<Form.Item
									className="mb-0"
									name="moqUnit">
									<Radio.Group className="custom-btn-group">
										{brokerListingButtons.moqUnit.map((item, index) => (
											<Radio.Button
												key={item.label}
												value={item.param}
												onChange={() =>
													setBrokerListingsPrams({
														...brokerListingsPrams,
														moqUnit: modifyBrokerUrl(brokerListingsPrams.moqUnit, item.param),
													})
												}>
												<span className="icon-block">
													{!item.icon ? (
														<img
															className="d-inline mb-1"
															alt={item.label}
															src={`${process.env.PUBLIC_URL}/${item.imgUrl}`}
														/>
													) : (
														<i className={item.icon} />
													)}
												</span>
												{item.label}
											</Radio.Button>
										))}
									</Radio.Group>
								</Form.Item>
							</div>
						)}
					</div>
				</Form>
			</Modal>
			{searchButtonInsideHeader ? (
				<Button
					size="large"
					icon={<i className="far fa-filter"></i>}
					onClick={() => setIsModalOpen(true)}
					className="btn btn-round bt-sm btn-outside-global-search">
					Advance
				</Button>
			) : (
				<Button
					icon={<i className="far fa-filter"></i>}
					onClick={() => setIsModalOpen(true)}
					className="btn rounded-pills btn-round bt-sm btn-inside-global-search">
					Advance
				</Button>
			)}
		</>
	);
};

export default AdvanceSearchFilter;
