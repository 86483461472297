import * as ENV from "../../../config";

export const DOMAIN_URL = ENV.DOMAIN_URL;
export const BROKER_DOMAIN_URL = ENV.BROKER_DOMAIN_URL;
export const API_URL = ENV.API_URL;
export const IMAGES_BASE_URL = ENV.IMAGES_BASE_URL;
export const IMAGES_BASE_URL1 = ENV.IMAGES_BASE_URL1;
export const VERSION = ENV.VERSION;
export const STRIPE_KEY = ENV.STRIPE_KEY;
export const S3_BASE_URL = ENV.S3_IMAGES_BASE_URL;

// broker module
export const CREATE_DEAL = `listing`;
export const GET_DEAL = `listing/:listing_id`;
export const GET_DEAL_LISTINGS = `listing/public/deals`;
export const PART_NUMBER = `broker/part_no`;
export const WISHLIST = `wishlist`;

export const GET_DEAL_LISTING_SIDEBAR = `broker/sidebar_data`;

export const Inquiry_BIDS = `listing/inquiry`;
export const CREATE_DUPLICATE_DEAL = `listing/duplicate-deal`;
export const UPDATE_DEAL = `listing/updateListing`;
export const UPDATE_INVENTORY = `listing/update-inventory`;
export const UPLOAD_FILES = `attachment`;
export const SELLER_BID = `listing/user-deals`;

// broker dropdown for category, sub category and brands
export const CATEGORY = `broker/category/list`;
export const BRANDS = `broker/brand/list`;

//delete admin listing
export const DELETE_ADMIN_LISTING = `admin/listing`;

//admin listing
export const ADMIN_LISTING = `admin/listing`;
export const ADMIN_OFFER_LISTING = `admin/listing/get-deals`;

export const ADMIN_Banner = `admin/banner`;

//admin token validation
export const TOKEN_VALIDATION = `admin/validate-token`;

//admin brands and category
export const ADMIN_CATEGORY = `broker/category/list`;
export const ADMIN_BRANDS = `broker/brand/list`;
export const SHOP_SOLAR_DEAL = `listing/public/deals`;
export const ADMIN_DASHBOARD_STATS = `dashboard/stats`;
export const ADMIN_DASHBOARD_DEALS = `dashboard/deals`;
export const ADMIN_DASHBOARD_GRAPH = `dashboard/graph`;

//admin update deal
export const ADMIN_UPDATE_BRANDS = `admin/listing`;

//admin part no
export const ADMIN_PART_NO = `broker/part_no`;

//broker chat
export const MARK_MSG_AS_READ = `offer/msg-read/:offer_id`;
export const GET_CHAT_BOX = `offer/get_offer/:offer_id`;
export const ADMIN_SEND_MESSAGE = `/offer/send-msg/:offer_id`;
export const UPDATE_BID = `offer/update-bid/:offer_id`;
export const UPDATE_QTY = `offer/sunhub-update-qty/:offer_id`;
export const UPDATE_TERMS = `offer/update-terms/:offer_id`;
export const UPDATE_OFFER_STATUS = `offer/offer-status/:offer_id`;
export const ADMIN_SEND_OFFER = `/offer/sunhub-offer-update`;
export const TAX_LEGAL = `/offer/tax-legal/:offer_id`;
export const GENERATE_PDF = `/offer/generate-pdf/:msg_id`;
export const BUYER_QUOTE = `/offer/buyer-quote/:offer_id`;
export const ADMIN_QUOTE = `offer/sunhub-quote/:msg_id`;
export const SELLER_QUOTE = `offer/seller-quote/:msg_id`;
export const QUOTE_PDF = `/offer/quotation-contract/:msg_id`;
export const NOTIFICATION_MESSAGE = `offer/sms-settings`;
export const NOTIFICATION_SETTING = `login-info/setting`;
export const GUEST_INFO = `offer/closed-chat`;
export const OFFER_STATUS = `offer/chat-status`;
