// client - buyer registration
export const BUYER = {
	register: {
		first_name: {
			req: "First name is required",
			max: "First name is required",
		},
		last_name: {
			req: "Last name is required",
			max: "Last name is required",
		},
		email: {
			req: "Email is required",
			reqvalid: "A valid email is required",
			invalid: "Please provide valid email address",
		},
		password: {
			req: "Password is required",
			reqvalid: "A min of 8 characters password required",
			min: "Password at least required 8 characters",
		},
		confirm_password: {
			match: "Password and confirm password should same",
		},
	},
	Product_catalog_list: {
		first_name: " Name is required",
		message: "Message is required",
		email: {
			req: "Email is required",
			reqvalid: "A valid email is required",
			invalid: "Please provide valid email address",
		},
	},
	email: {
		req: "Email is required",
		reqvalid: "A valid email is required",
		invalid: "Please provide valid email address",
	},

	signin: {
		email: {
			req: "Email is required",
			reqvalid: "A valid email is required",
			invalid: "Please provide valid email address",
		},
		password: {
			req: "Password is required",
			reqvalid: "A min of 8 characters password required",
			min: "Password at least required 8 characters",
		},
	},
	review: {
		comment: {
			req: "Comment is required",
		},
		rate: {
			req: "Rating is required",
		},
	},
};

export const GLOBAL = {
	name: (addonName = "") => {
		return {
			required: `${addonName} Name is required`,
		};
	},
	firstName: {
		required: "Name is required.",
	},
	email: {
		required: "Email is required.",
		valid: "A valid email is required.",
		invalid: "Please provide valid email address.",
	},
	contact: {
		required: "Phone number is required.",
	},
	subject: {
		required: "Subject is required.",
	},
	message: {
		required: "Message is required.",
	},
};

export const product_QNA = {
	question: {
		required: "This field is required",
	},
};

//sell an item
export const SELL_AN_ITEM = {
	category: { required: "Please select category" },
	subCategory: { required: "Please select sub category" },
	name: {
		max: "Title must be maximum 55 characters.",
		required: "Product title is required",
	},
	description: { required: "Description is required" },
	meta_name: {
		max: "Meta title must be maximum 100 characters.",
		required: "Meta Title is required",
	},
	meta_description: {
		required: "Meta description is required",
		max: "Meta description must be maximum 250 characters.",
	},
	price: {
		required: "Price is required",
		positive: "Price must be a positive value",
	},
	sellerPrice: {
		required: "Seller price is required",
		positive: "Seller price must be a positive value",
	},
	moq: {
		positive: "Moq should be atleast 1",
		required: "Moq is required",
	},
	cost_per_item: {
		positive: "Cost per item must be a positive value",
	},
	sku: {
		max: "SKU is required.",
	},
	part_number: {
		required: "Part number is required.",
	},
	condition: {
		required: "Condition is required",
	},
	weight: {
		required: "Weight is required",
	},
	length: {
		required: "Length is required",
	},

	width: {
		required: "Width is required",
	},
	height: {
		required: "Height is required",
	},
	length_unit: {
		required: "Length unit is required",
	},
	weight_unit: {
		required: "Weight unit is required",
	},
	width_unit: {
		required: "Width unit is required",
	},
	height_unit: {
		required: "Height unit is required",
	},
	measurements: {
		sameUnits: "Units of length, width and height should be same",
	},
	brand: {
		required: "Brand is required",
	},
	shipping_type: {
		required: "Shipping Type is required",
	},
	shipping_calculator: {
		required: "Shipping Calculator is required",
	},
	Custom_Shipping_Charges: {
		required: "Custom Shipping Charges is required",
	},
	supplier_order_number: {
		required: "Supplier order number is required",
	},

	tracking_url: {
		required: "Tracking Url is required",
	},

	reseller_id: {
		required: "Please Select Reseller",
	},

	tracking_id: {
		required: "Tracking Id is required",
	},
	price_scalling: {
		main: {
			required: "At least 1 slot is required or uncheck the price Scaling option ",
		},
		discount: {
			required: "Discounted Price is required",
		},
		currentPrice: {
			required: "Current Price is required",
		},
		min: {
			required: "Min Qty is required",
		},
		max_qty: {
			required: "Max Qty is required",
			greaterThen: "Max Qty should be greater than Min Qty",
		},
		priceScaleDiscount: {
			required: "Discount is required",
			greaterThenZero: "Discount should be greater than 0",
			uptoPrice: "Discount should be max upto current price",
			uptoHundred: "Discount should be max 100 %",
		},
		min_qty: {
			required: "Min Qty is required",
			lessThen: "Min Qty should be greater than previous slot max Qty",
		},
	},
	related_products: {
		required_four: "Related product must be 4 or none",
	},
};
export const PROMO_ITEM = {
	promoName: {
		required: "Name is required",
	},
	commercial_address: {
		required: "Commercial Address is required",
	},

	lift_gate: {
		required: "Lift Gate is required",
	},
	text_note: {
		max: "Note must be maximum 255 characters.",
	},
};

// client - buyer registration
export const SELLER = {
	product: {
		title: "Title is required",
		description: "Please provide product description",
		meta: {
			title: "Meta Title is required",
			meta_title_length: "Max 55 characters allowed",
			description: "Meta description  is required",
		},
		category: "Please select category",
		price: {
			required: "Price is required",
			nonZero: "Price should be greater than 0",
		},
	},
	pricing: {
		sku: "SKU is required",
	},
	location: {
		name: "Name is required",
		city: "City is required",
		address: "Address is required",
		unit: "Unit is required",
		state: "State is required",
		zip: "Zip code is required",
		zip_eq: "Zip code is invalid",
	},
	business_profile: {
		city: "City is required",
		business_name: "Business Name is required",
		business_website: "URL is required",
		name: "Address Name is required",
		address1: "Address is required",
		zip: "Zip code is required",
		zip_eq: "Zip code is invalid",
	},
	account_security: {
		current_password: "Current Password is required",
		new_password: "New Password is required",
		password_confirmation: "Confirm Password is required",
	},
	security_question: {
		answer1: "",
		answer2: "",
		answer3: "",
	},
	shipping_info: {
		first_name: "First name is required",
		name: "name is required",
		last_name: "Last name is required",
		zip: "zip code is required",
		zip_eq: "Zip code is invalid",
		phone: "phone is required",
		address: "Address is required",
		city: "City is required",
		state: "State is required",
		country: "Country is required",
	},
	billing_info: {
		name: " name is required",
		billing_address: "Address is required",
		billing_city: "city is required",
		billing_state: "State is required",
		billing_zip: "Zip code is required",
		zip_eq: "Zip code is invalid",
		country: "Country is required",
	},
	about_company: {
		about_company_error: "About Company is required",
		returns_and_refunds: "Returns & Refunds is required",
		terms_and_conditions: "Terms & Conditions is required",
		privacy_policy: "Privacy Policy is required",
		shipping: "Shipping  is required",
	},
	checkout: {
		customer_phone: "Phone is required",
		customer_email: "Email is required",
		billing_first_name: "First name is required",
		billing_last_name: "Last name is required",
		billing_address: "Billing address  is required",
		billing_unit: "Billing unit  is required",
		billing_city: "Billing city is required",
		billing_state: "Billing state  is required",
		billing_zip: " zip code is required",
		zip_eq: "Zip code is invalid",
		billing_country: "Billing country is required",
		shipping_address: "Shipping address is required",
		shipping_city: "Shipping city is required",
		shipping_unit: "Shipping unit is required",
		shipping_state: "Shipping state is required",
		shipping_zip: "Shipping zip is required",
		shipping_country: "Shipping country is required",
	},
	resellers: {
		name: "Name is required",
		company_name: "company Name is required",
	},
	checkoutNew: {
		name: [
			{
				required: true,
				message: "Name is required",
			},
		],
		first_name: [
			{
				required: true,
				message: "First Name is required",
			},
		],
		last_name: [
			{
				required: true,
				message: "Last Name is required",
			},
		],
		city: [
			{
				required: true,
				message: "City is required",
			},
		],
		state: [
			{
				required: true,
				message: "State is required",
			},
		],
		shipping_state: [
			{
				required: true,
				message: "State is required",
			},
		],
		address: [
			{
				required: true,
				message: "Address is required",
			},
		],
		company_name: [
			{
				required: true,
				message: "Company Name is required",
			},
		],
		Number: [
			{
				required: true,
				message: "Phone number is required",
			},
			// {
			// 	validator: (_, value) => {
			// 		if (value && !/^[0-9]+$/.test(value)) {
			// 			return Promise.reject("Moq must be a number");
			// 		}
			// 		return Promise.resolve();
			// 	},
			// },
		],
		code: [
			{
				required: true,
				message: "Zip Code is required",
			},
		],
		billing_address: [
			{
				required: true,
				message: "Address is required",
			},
		],
		email: [
			{ required: true, message: "Please input your email." },
			{ type: "email", message: "The input is not valid email." },
		],
		// payment method
		card_holder_name: [
			{
				required: true,
				message: "Card Holder Name  is required",
			},
		],
		cardNumber: [
			{
				required: true,
				message: "Card Number is required",
			},
		],
		card_cvv: [
			{
				required: true,
				message: "CVC Number is required",
			},
		],
		date: [
			{
				required: true,
				message: "Expiry Date is required",
			},
		],
		payment_state: [
			{
				required: true,
				message: "State is required",
			},
		],
	},
};

// api - error overriding
export const SOMETHING_WRONG = "Something went wrong. Not able to proceed with your request.";
export const IN_ACTIVE_USER = "Seems not an active user, please contact with admin";
