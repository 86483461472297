import React, { Fragment, useEffect, useRef, useState } from "react";
import { Input, AutoComplete } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import filterSolarDeals from "../../../../pages/broker/api/broker/filter_solar_deals";
import { filterProducts } from "../../../../pages/custom-order-fulfillment/main/filterProducts";
import { searchStore } from "../../../../api/store-front/search-store";
import { IMAGES_BASE_URL, RESELLER_IDs } from "../../../../config";
import { setPathParams } from "../../../pages/shop/services/setParams";
import { LISTING_PAGE, STORE_FRONT_PAGE } from "../../../../constants/routes";
import { DEAL_LISTING_PAGE } from "../../../../pages/broker/constants/routes";
import AdvanceSearchFilter from "./AdvanceSearchFilter";

const GlobalSearch = (props) => {
	const { userData, showAdvance, showButton, inputSearchPrefixIcon, showSearchOnMobile } = props;
	let history = useHistory();
	const [storeSuggestions, setStoreSuggestions] = useState({ data: [], loading: false });
	const [solarDealSuggestions, setSolarDealSuggestions] = useState({ data: [], loading: false });
	const [productSuggestions, setProductSuggestions] = useState({ data: [], loading: false });
	const [showDropDown, setShowDropDown] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [categoryIdProductsFilter] = useState(0);
	const sortedCategories = {};

	let searchOptions = [];
	const searchDropDown = useRef();

	const renderSolarDealSuggestion = ({ listing_id, title, sub_category, profileImages, part_no }) => {
		return (
			<>
				<li
					key={listing_id}
					className="dropdown-item"
					onClick={(e) => {
						onSearch(title, { listing_id, title, suggestionType: "SolarDeal" });
					}}>
					<Link
						to="#."
						className="dd-content">
						<img
							className="img-fluid img-thumbnail"
							src={
								profileImages && profileImages.length > 0
									? `${IMAGES_BASE_URL}${profileImages[0].path}`
									: `${process.env.PUBLIC_URL}/assets/images/products/shop/large-1.jpg`
							}
							alt={title}
							onError={(e) => {
								e.target.src = `${process.env.PUBLIC_URL}/assets/images/replacement-broken-image.jpg`;
							}}
						/>

						<div className="text-area">
							<h6 className="product-title">{title}</h6>
							<span className="product-cat">
								{sub_category} | {part_no}
							</span>
						</div>
					</Link>
				</li>
			</>
		);
	};
	const renderProductSuggestion = (title, image, catName, part_number) => (
		<Fragment key={title}>
			<li
				className="dropdown-item"
				onClick={(e) => {
					onSearch(title, { suggestionType: "Product" });
				}}>
				<Link className="dd-content">
					<img
						onError={(e) => {
							e.target.src = `${process.env.PUBLIC_URL}/assets/images/replacement-broken-image.jpg`;
						}}
						src={
							image && image.length > 0
								? ` ${IMAGES_BASE_URL}${image[0].path}`
								: `${process.env.PUBLIC_URL}/assets/images/icons/store-avatar.png`
						}
						className="img-fluid"
						alt=""
					/>

					<div className="text-area">
						<h6 className="product-title">{title}</h6>
						<span className="product-cat">
							{catName} {part_number && " | " + part_number}
						</span>
					</div>
				</Link>
			</li>
		</Fragment>
	);

	const renderStoreSuggestion = ({ userId, storeName, email, profileImages }) => (
		<li
			key={userId}
			className="dropdown-item"
			onClick={(e) => {
				onSearch(storeName, { userId, storeName, suggestionType: "Store" });
			}}>
			<Link
				to={"#"}
				className="dd-content">
				<img
					onError={(e) => {
						e.target.src = `${process.env.PUBLIC_URL}/assets/images/replacement-broken-image.jpg`;
					}}
					src={
						profileImages && profileImages.length > 0
							? ` ${IMAGES_BASE_URL}${profileImages[0].path}`
							: `${process.env.PUBLIC_URL}/assets/images/icons/store-avatar.png`
					}
					className="img-fluid"
					alt=""
				/>

				<div className="text-area">
					<h6 className="product-title">{storeName}</h6>
					{RESELLER_IDs.includes(email) ? (
						<span className="product-cat">{email}</span>
					) : (
						<span className="product-cat">Storefront</span>
					)}
				</div>
			</Link>
		</li>
	);

	const customProductsSearch = async (value) => {
		if (value !== "") {
			setShowDropDown(true);
		} else if (value === "") {
			setShowDropDown(false);
		}
		setProductSuggestions({ ...productSuggestions, loading: true });
		setStoreSuggestions({ ...storeSuggestions, loading: true });
		setSolarDealSuggestions({ ...solarDealSuggestions, loading: true });
		setSearchString(value);
		const response = await filterSolarDeals(
			{ search: value },
			userData && userData.data && userData.data.auth && userData.data.auth.access_token
		);
		if (response && response) {
			setSolarDealSuggestions({ data: response.deals.slice(0, 5) || [], loading: false });
		}
		filterProducts(value, (data) => {
			setProductSuggestions({ data: data || [], loading: false });
		});
		searchStore(
			{ search: value, limit: 5 },
			(stores) => {
				setStoreSuggestions({
					data: (stores || []).map((store, index) => {
						return {
							name: store.name,
							user_id: store.user_id,
							email: store.email,
							profileImages: store.profile_images,
						};
					}),
					loading: false,
				});
			},
			null,
			{ search: value }
		);
	};
	const solarDealSearchOptions =
		solarDealSuggestions &&
		solarDealSuggestions.data &&
		solarDealSuggestions.data.map((item) => {
			const singleImage = item && item.attachments.filter((img) => img.type === "Image");
			return renderSolarDealSuggestion({
				listing_id: item.listing_id,
				title: item.title,
				sub_category: item.sub_category,
				profileImages: singleImage,
				part_no: item.part_no,
			});
		});
	const storeSearchOptions =
		storeSuggestions.data &&
		storeSuggestions.data.map((store) => {
			return renderStoreSuggestion({
				storeName: store.name,
				userId: store.user_id,
				email: store.email,
				profileImages: store.profileImages,
			});
		});
	const productSearchOptions =
		productSuggestions.data &&
		productSuggestions.data.map((item) => {
			const sortedImages =
				item &&
				item.images.sort((a, b) => {
					if (a.orderofimage === null || a.orderofimage === undefined) return 1;
					if (b.orderofimage === null || b.orderofimage === undefined) return -1;
					return a.orderofimage - b.orderofimage;
				});
			return renderProductSuggestion(item.name, sortedImages, item.category && item.category.name, item.part_number);
		});
	productSearchOptions.length > 0 &&
		searchOptions.push(
			<div className="search-product-title">
				<i className="far fa-box mr-2"></i>
				<span>{searchString}</span>
				in Products
			</div>
		);
	searchOptions = [...searchOptions, ...productSearchOptions];

	solarDealSearchOptions &&
		solarDealSearchOptions.length > 0 &&
		searchOptions.push(
			<div className="search-product-title">
				<i className="far fa-box mr-2"></i>
				<span>{searchString}</span>
				in Solar Deals
			</div>
		);
	searchOptions = [...searchOptions, ...solarDealSearchOptions];

	storeSearchOptions.length > 0 &&
		searchOptions.push(
			<div className="search-product-title">
				<i className="far fa-store-alt mr-2"></i>
				<span>{searchString}</span>
				in Stores
			</div>
		);
	searchOptions = [...searchOptions, ...storeSearchOptions];
	const searchOnEnterKey = (e) => {
		if (e.key === "Enter") {
			onSearch(null);
		}
	};
	const onSearch = (value, option) => {
		setShowDropDown(false);
		if (!option || (option && option.suggestionType === "Product")) {
			let searchString_1 = value ? value : searchString;
			let selectedCat = { id: 0, name: "all" };
			if (categoryIdProductsFilter !== 0) {
				selectedCat = sortedCategories[categoryIdProductsFilter];
			}
			const category = selectedCat.name.replace(/\s+/g, "-").toLowerCase();
			if (window.location.href.includes(`${LISTING_PAGE}`)) {
				//search on listing page
				let params = {
					category: category,
					searchString: searchString_1 ? searchString_1 : "resetSearchString",
				};
				let url = setPathParams(params, true, undefined, `${LISTING_PAGE}`);
				history.push(`${url}`);
			} else if (!searchString_1) {
				history.push(`${LISTING_PAGE}/${category !== `all` ? `ca_${category}` : category}`);
			} else {
				const q = searchString_1;
				history.push(`${LISTING_PAGE}/${category !== `all` ? `ca_${category}` : category}?q=${q}`);
			}
		} else if (option && option.suggestionType === "Store") {
			history.push(`${STORE_FRONT_PAGE(option.userId, option.storeName)}`);
		} else if (option && option.suggestionType === "SolarDeal") {
			history.push(`${DEAL_LISTING_PAGE}?q=${encodeURIComponent(option.title)}`);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideModalEvent, true);
	}, [showDropDown]);
	const handleOutsideModalEvent = (e) => {
		if (searchDropDown.current && !searchDropDown.current.contains(e.target)) {
			setShowDropDown(false);
		}
	};

	return (
		<>
			{showSearchOnMobile ? (
				<>
					<AutoComplete
						onChange={(value) => {
							customProductsSearch(value);
						}}
						onSelect={(value, option) => {
							onSearch(value, option);
						}}
						onClick={() => searchString && setShowDropDown(true)}
						value={searchString || ""}>
						<Input
							className="rounded"
							size="large"
							onSearch={onSearch}
							onPressEnter={searchOnEnterKey}
							placeholder="Search our marketplace...."
							prefix={
								inputSearchPrefixIcon && (
									<SearchOutlined
										style={{ fontSize: "26px" }}
										className="site-form-item-icon"
									/>
								)
							}
						/>
					</AutoComplete>
					<button
						className="btn btn-primary"
						type="button"
						onClick={(e) => {
							onSearch(null);
						}}>
						<i className="fal fa-search"></i>
					</button>
					{searchOptions.length > 0 && (
						<div
							className="dropdown header-search-dd"
							ref={searchDropDown}>
							<div className={`dropdown-menu ${showDropDown && "show"}`}>
								<div className="products-section">
									<ul className="dd-suggestion-lists">
										{searchOptions.map((option, index) => {
											return option;
										})}
									</ul>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<div className="header-autocomplete-search">
					<AutoComplete
						onChange={(value) => {
							customProductsSearch(value);
						}}
						onSelect={(value, option) => {
							onSearch(value, option);
						}}
						onClick={() => searchString && setShowDropDown(true)}
						value={searchString || ""}>
						<Input
							className="rounded"
							size="large"
							onSearch={onSearch}
							onPressEnter={searchOnEnterKey}
							placeholder="Search our marketplace...."
							prefix={
								inputSearchPrefixIcon && (
									// <SearchOutlined
									// 	style={{ fontSize: "26px" }}
									// 	className="site-form-item-icon"
									// />
									<i className="far fa-search text-xl"></i>
								)
							}
						/>
					</AutoComplete>

					{showAdvance === "advance" && <AdvanceSearchFilter searchButtonInsideHeader={false} />}
					{showButton === "button" && (
						<button
							className="btn btn-primary"
							type="button"
							onClick={(e) => {
								onSearch(null);
							}}>
							<i className="fal fa-search"></i>
						</button>
					)}
					{searchOptions.length > 0 && (
						<div
							className="dropdown header-search-dd"
							ref={searchDropDown}>
							<div className={`dropdown-menu ${showDropDown && "show"}`}>
								<div className="products-section">
									<ul className="dd-suggestion-lists">
										{searchOptions.map((option, index) => {
											return option;
										})}
									</ul>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

function mapStateToProps(state) {
	return {
		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
	};
}

export default connect(mapStateToProps)(GlobalSearch);
