import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ForgotPassword from "../../forms/buyer/forgotPassword";

Modal.setAppElement("#root");

const ForgotPasswordModal = ({ onSubmit, showpop, closeform }) => {
	const customStyles = {
		content: {
			top: "50%",
			transform: "translateY(-50%)",
		},
		overlay: {
			backgroundColor: "rgba(77,77,77,0.6)",
			zIndex: "10000",
		},
	};

	return (
		<Modal
			isOpen={showpop}
			style={customStyles}
			contentLabel="Forgot Password Modal"
			className="modal-dialog modal-dialog-centered"
			id="forgot-password-modal">
			<div className="modal-content">
				<div className="modal-body">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={closeform}>
						<span aria-hidden="true">
							<i className="fal fa-times"></i>
						</span>
					</button>
					<div className="form-box">
						<div className="form-tab">
							<Tabs
								selectedTabClassName="show"
								defaultIndex={0}>
								<TabList className="nav nav-pills nav-fill">
									<Tab className="nav-item">
										<span className="nav-link">Forgot Password</span>
									</Tab>
								</TabList>

								<div className="tab-content">
									<TabPanel style={{ paddingTop: "2rem" }}>
										<ForgotPassword onSubmit={onSubmit} />
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps)(ForgotPasswordModal);
