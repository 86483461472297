// import { POST } from "../../api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../../lang/en/messages";
import { SEARCH_PRODUCT } from "../../../constants/api";
import { POST } from "../../../api/api";

export const filterProducts = async (query = {}, callback, inStock = false) => {
	const data = {
		fields: ["uuid", "id", "name", "location", "total_qty", "final_price", "price", "description", "part_number"],
		with: ["images", "category"],
		where: [
			["is_active", 1],
			["status", "approved"],
		],
		limit: 5,
	};
	if (inStock) {
		data.where = [["is_active", 1]];
	}

	if (typeof query !== "undefined" && Object.keys(query).length > 0) {
		data.where_in = [];

		//search string
		if (query.search && query.search !== "") {
			data.query = {
				value: "+*" + query.replace(/\s+/g, "* +*") + "*",
				fields: ["name", "part_number", "sku"],
			};
		}

		//category and subcategory
	}
	POST(SEARCH_PRODUCT, data, function (data) {
		if (data.status && data.status) {
			const products = [];
			for (let i = 0; i < data.data.length; i++) {
				const product = data.data[i];

				products.push({
					id: product.id,
					name: product.name,
					pictures: product.images,
					images: product.images,
					category: product.category,
					uuid: product.uuid,
					total_qty: product.total_qty,
					price: product.price,
					final_price: product.final_price,
					part_number: product.part_number,
				});
			}
			callback(products);
		} else {
			if (data.http_error) {
				toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						toast.error(data.errors[i]);
					}
				} else {
					toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
