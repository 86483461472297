import React from "react";
import { Link } from "react-router-dom";
import { SlideToggle } from "react-slide-toggle";
import { safeContent } from "../../../utils";

function Card(props) {
	const { title, expanded, adClass, type = "default", showChildrenDrawer, cat, customLink, subTitle } = props;

	return "default" === type ? (
		<SlideToggle collapsed={expanded ? false : true}>
			{({ onToggle, setCollapsibleElement, toggleState }) => (
				<div
					className={`card ${adClass}`}
					onClick={() => (showChildrenDrawer && showChildrenDrawer(cat)) || ""}>
					<div
						className="card-header"
						onClick={onToggle}>
						<h2 className="card-title">
							{customLink ? (
								<span
									className={`toggle-button ${toggleState && toggleState.toLowerCase()}`}
									onClick={onToggle}
									style={{ height: "auto" }}>
									<Link to={"/brands"}>{title}</Link>
								</span>
							) : (
								<span
									className={`toggle-button ${toggleState.toLowerCase()}`}
									// dangerouslySetInnerHTML={safeContent(title)}
									style={{ height: "auto" }}>
									{title}
									{subTitle && <small className="shipping-date text-muted">{subTitle}</small>}
								</span>
							)}
						</h2>
					</div>
					<div ref={setCollapsibleElement}>{props.children && <div className="card-body">{props.children}</div>}</div>
				</div>
			)}
		</SlideToggle>
	) : (
		<SlideToggle collapsed={expanded ? false : true}>
			{({ onToggle, setCollapsibleElement, toggleState }) => (
				<div className={`acc-item ${adClass}`}>
					<h5>
						<span
							className={`toggle-button ${toggleState.toLowerCase()}`}
							dangerouslySetInnerHTML={safeContent(title)}
							onClick={onToggle}
							style={{ height: "auto" }}></span>
					</h5>
					<div ref={setCollapsibleElement}>
						<div className="collapse-wrap">{props.children}</div>
					</div>
				</div>
			)}
		</SlideToggle>
	);
}

export default React.memo(Card);
